import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UserContext } from "../contexts/UserProvider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getNewLightTheme, getNewDarkTheme, fonts, colors } from "./Theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DestinationTabPanel(props) {
  const [state, dispatch] = React.useContext(UserContext);
  const useStyles = makeStyles(
    state.darkModeEnabled ? getNewDarkTheme(colors) : getNewLightTheme(colors)
  );
  const classes = useStyles();
  const [activeIndexOfSelection, setActiveIndex] = React.useState(0);

  useEffect(() => {
    findGroupForActiveDest();
    return () => {};
  }, [state.currentSelecDest.DestinationNumber]);

  const handleChange = (event, newValue) => {
    dispatch({
      type: "changeDestinationTabsIndex",
      value: newValue,
    });
  };

  function findGroupForActiveDest() {
    let found = false;
    state.firebaseData.Destinations.forEach((destination) => {
      if (
        destination.DestinationNumber ==
        state.currentSelecDest.DestinationNumber
      ) {
        found = true;
        forwardGroup(destination.Distribution);
        return;
      }
    });
    if (!found) {
      setActiveIndex(state.distributions.length + 1000);
    }
  }

  function forwardGroup(group) {
    state.distributions.forEach((distribution, index) => {
      if (group == distribution) {
        setActiveIndex(index);
      }
    });
  }

  const StyledTab = withStyles((theme) => ({}))((props) => (
    <Tab
      style={{
        fontFamily: fonts.medium,
        color:
          props.activeindex == props.myindex &&
          props.myindex != state.destinationTabsIndex
            ? colors.mediumAlert1
            : state.darkModeEnabled
            ? colors.lightColor1
            : colors.darkColor1,
        fontSize: "14px",
        marginLeft: "25px",
        borderBottom:
          props.activeindex == props.myindex
            ? `2px solid ${colors.mediumAlert1}`
            : "",
      }}
      {...props}
    />
  ));

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "250px",
        overflowX: 'auto'
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",

        }}
      >
        <Tabs
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
          TabIndicatorProps={{
            style: {
              flexWrap: 'wrap',
              background: state.darkModeEnabled
                ? colors.lightColor1
                : colors.darkColor1,
            },
          }}
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          value={state.destinationTabsIndex}
          onChange={handleChange}
          aria-label="destination tabs"
        >
          {state.distributions.map((distribution, index) => {
            return (
              <StyledTab
                key={distribution}
                label={`${distribution}`}
                {...a11yProps(index)}
                activeindex={activeIndexOfSelection}
                myindex={index}
              />
            );
          })}
        </Tabs>
      </Box>

      {state.distributions.map((distribution, index) => {
        return (
          <TabPanel
            value={state.destinationTabsIndex}
            index={index}
            key={distribution}
          >
            <Box display="flex" justifyContent="left" flexWrap="wrap" gap="5px">
              {state.firebaseData.Destinations.map((destination, index) =>
                destination.Distribution == distribution ? (
                  <Button
                    className={
                      state.panelLocked || state.globallyLocked
                        ? classes.lockedButton
                        : destination.DestinationNumber ===
                          state.currentSelecDest.DestinationNumber
                        ? classes.selectedDestButton
                        : classes.destinationButtons
                    }
                    disabled={state.panelLocked || state.globallyLocked}
                    onClick={() => {
                      dispatch({
                        type: "selectDestination",
                        value: destination,
                        value2: index,
                      });
                    }}
                    key={destination.DestinationNumber}
                  >
                    <Box style={{ width: 220 }}>
                      <Typography
                        className={classes.headlineInButton}
                        style={{ fontFamily: fonts.medium }}
                      >
                        {destination.DestinationAlias}
                      </Typography>

                      <div style={{paddingTop: "3px", borderTop: `1px solid ${state.darkModeEnabled
                ? colors.darkColor1
                : colors.lightColor1}`}}>
                      <Typography
                        style={{
                          fontFamily: fonts.medium,
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        {destination.CurrentSourceName}{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: fonts.thin,
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                        >
                        {destination.Format}{" "}
                      </Typography>
                        </div>
                    </Box>
                  </Button>
                ) : null
              )}
            </Box>
          </TabPanel>
        );
      })}
    </Box>
  );
}
