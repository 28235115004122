export const colors = {
  lightColor1: "#c3c4c5",
  lightColor2: "#d2d3d3",
  mediumColor: "#4C413C",
  darkColor1: "#272422",
  darkColor2: "#3e3f42",
  mediumAlert1: "#0a61a3",
  mediumAlert2: "#ed580d",
  danger1: "#F73A31",
  disabledColor: "#D2CFCD",
};

export const otherStyling = {
  borderRadius: "5px",
};

export const fonts = {
  thin: "Regular",
  medium: "SemiBold",
  bold: "Bold",
};

export function getNewLightTheme(colors) {
  return {
    backgroundArea: {
      borderColor: colors.darkColor1,
      paddingTop: 1,
      marginTop: 1,
      minWidth: 350,
      minHeight: 50,
    },

    destinationButtons: {
      boxShadow: "0px 8px 24px #888888",
      backgroundColor: colors.darkColor1,
      color: colors.lightColor1,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      textAlign: "left",
      borderRadius: otherStyling.borderRadius,
      "&:hover": {
        backgroundColor: colors.darkColor1,
        boxShadow: `0 0 0 5px ${colors.mediumAlert1}`,
        color: "white",
      },
    },

    selectedDestButton: {
      boxShadow: "0px 8px 24px #888888",
      backgroundColor: colors.mediumAlert1,
      color: "white",
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,
      border: `5px solid ${colors.mediumAlert1}`,
      "&:hover": {
        backgroundColor: colors.mediumAlert1,
      },
    },

    takeButton: {
      boxShadow: "0px 8px 24px #888888",
      minWidth: "70%",
      minHeight: 50,
      marginTop: 20,
      paddingTop: 10,
      borderRadius: otherStyling.borderRadius,
      backgroundColor: colors.mediumAlert2,
      color: colors.lightColor1,
      "&:hover": {
        backgroundColor: colors.mediumAlert1,
      },
    },

    lockButton: {
      boxShadow: "0px 8px 24px #888888",
      flexGrow: 1,
      minHeight: 50,
      backgroundColor: colors.mediumColor,
      color: colors.lightColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.darkColor1,
        color: colors.lightColor1,
      },
    },
    MVButton: {
      boxShadow: "0px 8px 24px #888888",
      minWidth: "5%",
      minHeight: 50,
      backgroundColor: colors.mediumColor,
      color: colors.lightColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumAlert1,
        color: colors.lightColor1,
      },
    },

    ControllerButtonDisabled: {
      boxShadow: "0px 8px 24px #888888",
      minWidth: "5%",
      minHeight: 50,
      backgroundColor: colors.mediumColor,
      color: colors.lightColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumAlert1,
        color: colors.lightColor1,
      },
    },

    ControllerButtonEnabled: {
      boxShadow: "0px 8px 24px #888888",
      minWidth: "5%",
      minHeight: 50,
      backgroundColor: colors.mediumAlert1,
      color: colors.lightColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumColor,
        color: colors.lightColor1,
      },
    },

    lockButtonWhileLocked: {
      flexGrow: 1,
      minHeight: 50,
      backgroundColor: colors.mediumAlert1,
      color: colors.lightColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumColor,
        color: colors.lightColor1,
      },
    },
    lockedButton: {
      backgroundColor: colors.disabledColor,
      color: colors.mediumColor,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,
    },

    headline: {
      color: colors.darkColor1,
      textAlign: "left",
      padding: 5,
      fontSize: "14px",
    },

    headlineS: {
      color: colors.darkColor1,
      textAlign: "left",
      padding: 5,
      fontSize: "14px",
    },

    sourceButtons: {
      boxShadow: "0px 8px 24px #888888",
      backgroundColor: colors.darkColor1,
      color: colors.lightColor1,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        boxShadow: `0 0 0 ${otherStyling.borderRadius} ${colors.mediumAlert2}`,
        backgroundColor: colors.darkColor1,
        color: "white",
      },
    },

    currentSelecSourcButton: {
      boxShadow: "0px 8px 24px #888888",
      backgroundColor: colors.mediumAlert2,
      color: "white",
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      border: `5px solid ${colors.mediumAlert2}`,
      "&:hover": {
        backgroundColor: colors.mediumAlert2,
        color: "white",
      },
    },

    srcButtonforDest: {
      boxShadow: "0px 8px 24px #888888",
      backgroundColor: colors.darkColor1,
      color: "white",
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,
      boxShadow: `0 0 0 5px ${colors.mediumAlert1}`,
      "&:hover": {
        backgroundColor: colors.darkColor1,
        color: "white",
      },
    },

    lockedButton: {
      backgroundColor: "#B6B5B2",
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,
    },

    headlineInButton: {
      fontSize: "14px",
      textAlign: "left",
    },
  };
}

export function getNewDarkTheme(colors) {
  return {
    backgroundArea: {
      paddingTop: 2,
      marginTop: 2,
      minWidth: 350,
      minHeight: 50,
    },

    destinationButtons: {
      backgroundColor: "#EEEEEE",
      color: colors.darkColor1,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,
      "&:hover": {
        backgroundColor: colors.lightColor1,
        boxShadow: `0 0 0 5px ${colors.mediumAlert1}`,
        color: colors.darkColor1,
      },
    },

    selectedDestButton: {
      backgroundColor: colors.mediumAlert1,
      color: colors.darkColor1,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      border: `5px solid ${colors.mediumAlert1}`,
      "&:hover": {
        backgroundColor: colors.mediumAlert1,
      },
    },

    takeButton: {
      minWidth: "70%",
      marginTop: 20,
      paddingTop: 10,
      minHeight: 50,
      backgroundColor: colors.mediumAlert2,
      color: colors.darkColor1,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumAlert1,
        color: colors.darkColor1,
      },
    },

    lockButton: {
      flexGrow: 1,
      minHeight: 50,
      backgroundColor: colors.mediumColor,
      color: colors.lightColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumAlert1,
        color: colors.lightColor1,
      },
    },

    MVButton: {
      minWidth: "5%",
      minHeight: 50,
      backgroundColor: colors.mediumColor,
      color: colors.darkColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumAlert1,
        color: colors.darkColor1,
      },
    },
    ControllerButtonDisabled: {
      minWidth: "5%",
      minHeight: 50,
      backgroundColor: colors.mediumColor,
      color: colors.lightColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumAlert1,
        color: colors.lightColor1,
      },
    },

    ControllerButtonEnabled: {
      minWidth: "5%",
      minHeight: 50,
      backgroundColor: colors.mediumAlert1,
      color: colors.lightColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumColor,
        color: colors.lightColor1,
      },
    },

    lockButtonWhileLocked: {
      flexGrow: 1,
      minHeight: 50,
      backgroundColor: colors.mediumAlert1,
      color: colors.darkColor1,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        backgroundColor: colors.mediumColor,
        color: colors.darkColor1,
      },
    },
    lockedButton: {
      backgroundColor: colors.disabledColor,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,
    },

    headline: {
      color: colors.lightColor1,
      textAlign: "left",
      padding: 5,
      fontSize: "14px",
    },

    headlineS: {
      color: colors.lightColor1,
      textAlign: "left",
      padding: 5,
      fontSize: "14px",
    },

    sourceButtons: {
      backgroundColor: "#EEEEEE",
      color: colors.darkColor1,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      "&:hover": {
        boxShadow: `0 0 0 ${otherStyling.borderRadius} ${colors.mediumAlert2}`,
        backgroundColor: colors.lightColor1,
        color: colors.darkColor1,
      },
    },

    currentSelecSourcButton: {
      backgroundColor: colors.mediumAlert2,
      color: colors.darkColor1,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      border: `5px solid ${colors.mediumAlert2}`,
      "&:hover": {
        backgroundColor: colors.mediumAlert2,
        color: colors.darkColor1,
      },
    },

    srcButtonforDest: {
      backgroundColor: colors.lightColor1,
      color: colors.darkColor1,
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,

      border: `5px solid ${colors.mediumAlert1}`,
      "&:hover": {
        backgroundColor: colors.lightColor1,
        color: colors.darkColor1,
      },
    },

    lockedButton: {
      backgroundColor: "#B6B5B2",
      width: 250,
      minHeight: 110,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: otherStyling.borderRadius,
    },

    headlineInButton: {
      fontSize: "14px",
      textAlign: "left",
    },
  };
}
