import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import Box from "@material-ui/core/Box";
import { RTCSubscriber } from "red5pro-webrtc-sdk";
import { UserContext } from "../contexts/UserProvider";

export default function Multiviewer(props) {
  const { currentUser, logout } = useAuth();
  const [state, dispatch] = React.useContext(UserContext);

  var globalSub;

  const start = async (vid) => {
    let passToken = await currentUser.getIdToken();
    const config = {
      protocol: "wss",
      port: state.streamingData[state.selectedRegion][props.feedID].port,
      host: state.streamingData[state.selectedRegion][props.feedID].host,
      app: "live",
      connectionParams: { username: currentUser.uid, password: passToken },
      streamName: props.feedID,
      rtcConfiguration: {
        iceServers: [
          { urls: "stun:stun.3.google.com:19302" },
          { urls: "stun:stun4.l.google.com:19302" },
        ],
        iceCandidatePoolSize: 2,
        bundlePolicy: "max-bundle",
      }, // See https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/RTCPeerConnection#RTCConfiguration_dictionary
      mediaElementId: `red5pro-subscriberServer+${props.feedID}`,
      subscriptionId:
        `MainChannel` + Math.floor(Math.random() * 0x10000).toString(16),
      videoEncoding: "NONE",
      audioEncoding: "NONE",
    };

    const config2 = config;

    try {
      const subscriber = new RTCSubscriber();
      globalSub = subscriber;
      await globalSub.init(config);
      await globalSub.subscribe();
      vid.play();
    } catch (e) {
      console.error(e);
      try {
        const subscriber = new RTCSubscriber();
        let newRegion = getFailoverRegion(state.firebaseData.region);
        config2.host = state.streamingData[newRegion][props.feedID].host;
        config2.port = state.streamingData[newRegion][props.feedID].port;
        globalSub = subscriber;
        await globalSub.init(config2);
        await globalSub.subscribe();
        vid.play();
      } catch (e) {
        console.error(e);
      }
    }
  };

  function getFailoverRegion(region) {
    if (region == "EU") {
      let answer = "NA";
      return answer;
    }

    if (region == "NA") {
      let answer = "AS";
      return answer;
    }

    if (region == "AS") {
      let answer = "EU";
      return answer;
    } else {
      let answer = "AS";
      return answer;
    }
  }

  useEffect(() => {
    let vid = document.getElementById(
      `red5pro-subscriberServer+${props.feedID}`
    );
    try {
      start(vid);
    } catch (e) {
      console.log(e);
    }

    return () => {
      try {
        globalSub.stop();
        globalSub.unsubscribe();
      } catch (e) {
        console.log(e);
      }
    };
  }, [props.feedID]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        style={{
          margin: "auto",
          resize: "both",
          overflow: "auto",
          padding: "15px",
          width: "960px",
          height: "540px",
        }}
        className="red5pro-media-container"
      >
        <video
          id={`red5pro-subscriberServer+${props.feedID}`}
          style={{ opacity: "100%" }}
          width={"100%"}
          height={"100%"}
          preload="none"
          autoPlay="autoplay"
          muted
          controls
          playsInline
        ></video>
      </Box>
    </div>
  );
}
