import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { UserContext } from "../contexts/UserProvider";
import { colors, otherStyling } from "./Theme";
import { Typography } from "@material-ui/core";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ErrorSnackbar() {
  const [state, dispatch] = React.useContext(UserContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "toggle_ErrorSnackbar", value: false });
  };

  return (
    <div>
      <Snackbar
        open={state.errorSnackOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: colors.danger1,
            height: "40px",
            borderRadius: otherStyling.borderRadius,
          }}
        >
          <Typography>{state.errorSnackMessage}</Typography>
        </Alert>
      </Snackbar>
    </div>
  );
}
