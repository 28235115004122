import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
import { Box, Typography } from "@material-ui/core";
import { AlertTitle, Card } from "@mui/material";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { colors, fonts, otherStyling } from "./Theme";
import { db } from "../firebase";
import CircularProgress from "@mui/material/CircularProgress";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: colors.darkColor1,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: colors.darkColor1,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.darkColor1,
      borderRadius: otherStyling.borderRadius,
    },
    "&:hover fieldset": {
      borderColor: colors.darkColor1,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.darkColor1,
    },
  },
});

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [logoURL, setLogoURL] = useState("");
  const [init, setInit] = useState(true);

  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/");
    } catch {
      setError("Failed to log in. ");
    }

    setLoading(false);
  }
  useEffect(() => {
    getBranding();
    document.body.style.backgroundColor = colors.darkColor1;
    document.body.style.backgroundRepeat = "repeat";
    document.body.style.backgroundSize = "cover";
  }, []);

  function getBranding() {
    var branding = db.collection("global").doc("branding");
    branding
      .get()
      .then((doc) => {
        if (doc.exists) {
          let brandingData = doc.data();
          colors.lightColor1 = brandingData.colors.lightColor1;
          colors.lightColor2 = brandingData.colors.lightColor2;
          colors.mediumColor = brandingData.colors.mediumColor1;
          colors.darkColor1 = brandingData.colors.darkColor1;
          colors.darkColor2 = brandingData.colors.darkColor2;
          colors.mediumAlert1 = brandingData.colors.alertColor1;
          colors.mediumAlert2 = brandingData.colors.alertColor2;
          colors.danger1 = brandingData.colors.dangerColor1;

          setLogoURL(brandingData.logoURL);
          setTimeout(() => {
            document.body.style.backgroundImage = `url(${brandingData.backgroundURL})`;
            setInit(false);
          }, 500);
        }
      })
      .catch((error) => {
        console.error("Error getting document: Global Streaming Data", error);
      });
  }

  return (
    <div>
      {init ? (
        <Box
          display="flex"
          justifyContent={"center"}
          style={{ paddingTop: "48vh" }}
        >
          <CircularProgress
            size="4rem"
            style={{ color: colors.darkColor1, opacity: "90%" }}
          />
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            style={{
              backgroundColor: colors.lightColor1,
              maxWidth: "450px",
              marginTop: "10%",
              paddingBottom: "15px",
              opacity: "100%",
              borderRadius: otherStyling.borderRadius,
            }}
          >
            <img
              src={logoURL}
              alt="Logo"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "83%",
                maxHeight: "45%",
                paddingTop: "20px",
                opacity: "100%",
              }}
            />
            <center>
              <Typography
                style={{
                  marginTop: "15px",
                  fontFamily: fonts.medium,
                  fontSize: "20px",
                  marginBottom: "15px",
                }}
              >
                Router Panel
              </Typography>
              {error && (
                <Alert
                  variant="outlined"
                  icon={false}
                  style={{
                    color: colors.danger1,
                    borderColor: colors.danger1,
                    width: "72%",
                    marginBottom: "25px",
                    paddingTop: "10px",
                    wordWrap: "break-word",
                    borderRadius: otherStyling.borderRadius,
                  }}
                >
                  <AlertTitle
                    sx={{
                      fontFamily: fonts.medium,
                      color: colors.danger1,
                    }}
                  >
                    {error}
                  </AlertTitle>
                </Alert>
              )}
            </center>

            <form onSubmit={handleSubmit}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "80%" },
                }}
                noValidate
                autoComplete="off"
                display="flex"
                flexWrap="wrap"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CssTextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  inputRef={emailRef}
                  required
                />
                <p />
                <CssTextField
                  label="Password"
                  variant="outlined"
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  inputRef={passwordRef}
                  required
                />
              </Box>
              <Button
                type="submit"
                disabled={loading}
                style={{
                  margin: "2%",
                  color: colors.lightColor1,
                  backgroundColor: colors.darkColor1,
                  width: "80%",
                  height: "50px",
                  borderWidth: "0",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "25px",
                  borderRadius: otherStyling.borderRadius,
                }}
              >
                <Typography style={{ fontFamily: fonts.medium }}>
                  Sign In
                </Typography>
              </Button>
            </form>
          </Card>
        </Box>
      )}
    </div>
  );
}
