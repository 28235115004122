import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UserContext } from "../contexts/UserProvider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getNewLightTheme, getNewDarkTheme, fonts, colors } from "./Theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5, pl: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SourceTabs(props) {
  const [state, dispatch] = React.useContext(UserContext);
  const useStyles = makeStyles(
    state.darkModeEnabled ? getNewDarkTheme(colors) : getNewLightTheme(colors)
  );
  const classes = useStyles();
  const [activeIndexOfSelection, setActiveIndex] = React.useState(0);

  useEffect(() => {
    findGroupForActiveSource();
    return () => {};
  }, [
    state.currentSelecSourc.SourceNumber,
    state.currentSelecDest.CurrentSourceNumber,
  ]);

  const handleChange = (event, newValue) => {
    props.handleNewSourceGroup(newValue);
    dispatch({
      type: "changeSourceTabsIndex",
      value: newValue,
    });
  };

  function findGroupForActiveSource() {
    let found = false;
    state.availableSources.forEach((source) => {
      if (source.SourceNumber == state.currentSelecSourc.SourceNumber) {
        found = true;
        forwardGroup(source.Distribution);
        return;
      }
    });
    if (!found) {
      setActiveIndex(state.distributions.length + 1000);
    }
  }
  function forwardGroup(group) {
    state.distributions.forEach((distribution, index) => {
      if (group == distribution) {
        setActiveIndex(index);
      }
    });
  }

  const StyledTab = withStyles((theme) => ({}))((props) => (
    <Tab
      style={{
        fontFamily: fonts.medium,
        color:
          props.activeindex == props.myindex &&
          props.myindex != state.sourceTabsIndex
            ? colors.mediumAlert2
            : state.darkModeEnabled
            ? colors.lightColor1
            : colors.darkColor1,
        fontSize: "14px",
        marginLeft: "25px",
        borderBottom:
          props.activeindex == props.myindex
            ? `2px solid ${colors.mediumAlert2}`
            : "",
      }}
      {...props}
    />
  ));

  return (
    <Box sx={{ width: "100%", overflowX: 'auto', minHeight: "250px",
  }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
        TabIndicatorProps={{
          style: {
            flexWrap: 'wrap',
            background: state.darkModeEnabled
            ? colors.lightColor1
            : colors.darkColor1,
          },
        }}
        variant="scrollable"
        scrollButtons={true}
        allowScrollButtonsMobile
        value={state.sourceTabsIndex}
        onChange={handleChange}
        aria-label="source tabs"
        >
          {state.distributions.map((distribution, index) => {
            return (
              <StyledTab
                key={distribution}
                label={`${distribution}`}
                {...a11yProps(index)}
                activeindex={activeIndexOfSelection}
                myindex={index}
              />
            );
          })}
          {/*           <StyledTab
            label="Match A Feeds"
            {...a11yProps(0)}
            activeIndex={activeIndexOfSelection}
            myIndex={0}
          />
          <StyledTab
            label="Match B Feeds"
            {...a11yProps(1)}
            activeIndex={activeIndexOfSelection}
            myIndex={1}
          />
          <StyledTab
            label="Non Match Related"
            {...a11yProps(2)}
            activeIndex={activeIndexOfSelection}
            myIndex={2}
          /> */}
        </Tabs>
      </Box>
      {state.distributions.map((distribution, index) => {
        return (
          <TabPanel
            value={state.sourceTabsIndex}
            index={index}
            key={distribution}
          >
            <Box display="flex" justifyContent="left" flexWrap="wrap" gap="5px">
              {state.availableSources.map((source, i) =>
                source.Distribution == distribution ? (
                  <Button
                    key={source.SourceNumber}
                    disabled={state.panelLocked || state.globallyLocked}
                    onClick={
                      state.takeButtonEnabled
                        ? () =>
                            dispatch({ type: "selectSource", value: source })
                        : () => props.takeOnSelect(source)
                    }
                    className={
                      state.panelLocked || state.globallyLocked
                        ? classes.lockedButton
                        : source.SourceNumber ==
                          state.currentSelecDest.CurrentSourceNumber
                        ? classes.srcButtonforDest
                        : source.SourceNumber ==
                          state.currentSelecSourc.SourceNumber
                        ? classes.currentSelecSourcButton
                        : classes.sourceButtons
                    }
                  >
                    <Box style={{ width: 220 }}>
                      <Typography
                        style={{ fontFamily: fonts.medium, textAlign: "left" }}
                      >
                        {source.SourceName}
                      </Typography>
                      <div style={{paddingTop: "3px", borderTop: `1px solid ${state.darkModeEnabled
                ? colors.darkColor1
                : colors.lightColor1}`}}>
                      <Typography
                        style={{
                          fontFamily: fonts.thin,
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        {source.Format}{" "}
                      </Typography>
                      </div>
                    </Box>
                  </Button>
                ) : null
              )}
            </Box>
          </TabPanel>
        );
      })}
    </Box>
  );
}
