import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { UserContext } from "../contexts/UserProvider";
import Button from "@material-ui/core/Button";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { Typography } from "@material-ui/core";
import { Card } from "react-bootstrap";
import { fonts, colors, otherStyling } from "./Theme";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

function createData(itemName, keyID, keyType, bindingIndex, distro) {
  return { itemName, keyID, keyType, bindingIndex, distro };
}

export default function ControllerSettingsTable(props) {
  const { currentUser } = useAuth();
  const [state, dispatch] = React.useContext(UserContext);
  const [pressedKey, setCurrentKey] = React.useState("No key");
  const [focused, setFocused] = React.useState("");

  const rows = [];

  const useStyles = makeStyles({
    keyIndicator: {
      position: "fixed",
      bottom: "78px",
      left: "35px",
      minWidth: "250px",
      backgroundColor: state.darkModeEnabled
        ? colors.lightColor1
        : colors.darkColor1,
      padding: "25px",
      color: state.darkModeEnabled ? colors.darkColor1 : colors.lightColor1,
      borderRadius: otherStyling.borderRadius,
      opacity:
        document.getElementById("ControllerConfigurator") == focused
          ? "0.9"
          : "0.5",
    },
    keyIndicatorText: {
      fontSize: "17px",
      padding: "5px",
    },
  });

  const classes = useStyles();

  let takeID = getCurrentBinding("take", 0);
  let takeData = createData("Take Button", takeID, "take", 0, "General");
  rows.push(takeData);

  let unselectID = getCurrentBinding("unselect", 0);
  let unselectData = createData(
    "Unselect Button",
    unselectID,
    "unselect",
    0,
    "General"
  );
  rows.push(unselectData);

  let lockID = getCurrentBinding("lock", 0);
  let lockData = createData("Lock Button", lockID, "lock", 0, "General");
  rows.push(lockData);

  let globalLockID = getCurrentBinding("globalLock", 0);
  let globalLockData = createData(
    "Global Lock Button",
    globalLockID,
    "globalLock",
    0,
    "General"
  );
  rows.push(globalLockData);
  let mvTriggerID = getCurrentBinding("toggleMV", 0);
  let mvTriggerData = createData(
    "Toggle Multiviewer",
    mvTriggerID,
    "toggleMV",
    0,
    "General"
  );
  rows.push(mvTriggerData);

  let destinationGroupForwardID = getCurrentBinding(
    "moveRightDestinationGroup",
    0
  );
  let destinationGroupForwardData = createData(
    "Move right Destination Group",
    destinationGroupForwardID,
    "moveRightDestinationGroup",
    0,
    "General"
  );
  rows.push(destinationGroupForwardData);

  let sourceGroupForwardID = getCurrentBinding("moveRightSourceGroup", 0);
  let sourceGroupForwardData = createData(
    "Move right Source Group",
    sourceGroupForwardID,
    "moveRightSourceGroup",
    0,
    "General"
  );
  rows.push(sourceGroupForwardData);

  let destinationGroupBackwardID = getCurrentBinding(
    "moveLeftDestinationGroup",
    0
  );

  let destinationGroupBackwardData = createData(
    "Move left Destination Group",
    destinationGroupBackwardID,
    "moveLeftDestinationGroup",
    0,
    "General"
  );
  rows.push(destinationGroupBackwardData);

  let sourceGroupBackwardID = getCurrentBinding("moveLeftSourceGroup", 0);
  let sourceGroupBackwardData = createData(
    "Move left Source Group",
    sourceGroupBackwardID,
    "moveLeftSourceGroup",
    0,
    "General"
  );
  rows.push(sourceGroupBackwardData);

  state.availableSources.forEach((element, index) => {
    let keyID = getCurrentBinding("source", index);
    let data = createData(
      element.SourceName,
      keyID,
      "source",
      index,
      element.Distribution
    );
    rows.push(data);
  });

  state.firebaseData.Destinations.forEach((element, index) => {
    let keyID = getCurrentBinding("destination", index);
    let data = createData(
      element.DestinationAlias,
      keyID,
      "destination",
      index,
      element.Distribution
    );
    rows.push(data);
  });

  function getCurrentBinding(type, indexExt) {
    let currentBinding = "not defined";
    let bindings = state.controllerBindings;
    bindings.forEach((element, index) => {
      if (element.bindingIndex == indexExt && element.keyType == type) {
        currentBinding = element.keyID;
        return;
      }
    });
    return currentBinding;
  }

  function uploadBinding(newBindings) {
    db.collection("users")
      .doc(currentUser.uid)
      .update({
        ControllerBindings: newBindings,
      })
      .then(function () {});
  }

  function addBinding(item) {
    if (pressedKey !== "No key") {
      let newBindingCollection = state.controllerBindings;
      let keyID = pressedKey;
      let newBinding = {
        keyID: keyID, //key event e.g. a
        keyType: item.keyType, //source or destination?
        bindingIndex: item.bindingIndex, //index if binding in sources
        itemName: item.itemName,
      };

      if (!checkBindingExists(newBinding)) {
        newBindingCollection.push(newBinding);
        uploadBinding(newBindingCollection);
        getCurrentBinding(newBinding.bindingIndex, newBinding.keyType);
      } else {
        dispatch({
          type: "set_ErrorSnackbarMessage",
          value:
            "Key already assigned - choose a different key or remove binding",
        });
        dispatch({ type: "toggle_ErrorSnackbar", value: true });
      }
    } else {
      dispatch({
        type: "set_ErrorSnackbarMessage",
        value: "No key to assign - press a key to bind to your item.",
      });
      dispatch({ type: "toggle_ErrorSnackbar", value: true });
    }
    document.getElementById("ControllerConfigurator").focus();
  }

  function removeBinding(item) {
    let newBindingCollection = state.controllerBindings;

    newBindingCollection.forEach((element, index) => {
      if (
        element.bindingIndex == item.bindingIndex &&
        element.keyType == item.keyType
      ) {
        newBindingCollection.splice(index, 1);
        uploadBinding(newBindingCollection);
      }
    });
    document.getElementById("ControllerConfigurator").focus();
  }

  function checkBindingExists(bindingToCheck) {
    let bindingExists = false;
    let bindings = state.controllerBindings;
    bindings.forEach((element) => {
      if (
        element.keyID == bindingToCheck.keyID ||
        (element.bindingIndex == bindingToCheck.bindingIndex &&
          element.keyType == bindingToCheck.keyType)
      ) {
        bindingExists = true;
      }
    });
    return bindingExists;
  }

  function handleKeyUp(event) {
    setCurrentKey(event.key);
  }

  useEffect(() => {
    dispatch({ type: "disableController" });
    document.getElementById("ControllerConfigurator").focus();
    setInterval(() => {
      let focusOn = document.activeElement;
      setFocused(focusOn);
    }, 250);
    return () => {
      dispatch({ type: "disableControllerSettings" });
    };
  }, []);

  return (
    <div
      id="ControllerConfigurator"
      tabIndex={0}
      onKeyDown={handleKeyUp}
      style={{
        outline: "none",
        paddingTop: "30px",
      }}
    >
      <Card className={classes.keyIndicator} >
        <Typography className={classes.keyIndicatorText}>
          {document.getElementById("ControllerConfigurator") == focused ? (
            <>
              Detected key: <b>{pressedKey}</b>
            </>
          ) : (
            <>Click here to assign key</>
          )}
        </Typography>
      </Card>
      <Box display="flex" justifyContent={"left"} alignItems={"center"} gap="10px">
      <VideogameAssetIcon></VideogameAssetIcon>
      <Typography>Controller settings</Typography>
      </Box>
      <div style={{maxWidth: "90vw", overflowX: "auto"}}>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontFamily: fonts.bold,
                  color: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.darkColor1,
                }}
              >
                Item
              </TableCell>
              <TableCell
                style={{
                  fontFamily: fonts.bold,
                  color: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.darkColor1,
                }}
                align="left"
              >
                Distribution
              </TableCell>
              <TableCell
                style={{
                  fontFamily: fonts.bold,
                  color: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.darkColor1,
                }}
                align="left"
              >
                Assigned Key
              </TableCell>
              <TableCell
                style={{
                  fontFamily: fonts.bold,
                  color: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.darkColor1,
                }}
                align="left"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={`${row.itemName}` + `${row.keyID}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{
                  fontFamily: fonts.bold,
                  backgroundColor:
                    index % 2 == 0 && state.darkModeEnabled
                      ? "#3D3D3D"
                      : index % 2 == 0 && !state.darkModeEnabled
                      ? colors.lightColor2
                      : index % 2 != 0 && state.darkModeEnabled
                      ? "#2d2e28"
                      : index % 2 != 0 && !state.darkModeEnabled
                      ? colors.lightColor1
                      : "#2d2e28",
                }}
              >
                <TableCell
                  style={{
                    color: state.darkModeEnabled
                      ? colors.lightColor1
                      : colors.darkColor1,
                  }}
                >
                  <Typography style={{ fontSize: "12px" }}>
                    {row.itemName}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    color: state.darkModeEnabled
                      ? colors.lightColor1
                      : colors.darkColor1,
                  }}
                  align="left"
                >
                  <Typography style={{ fontSize: "12px" }}>
                    {row.distro}
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    color: state.darkModeEnabled
                      ? colors.lightColor1
                      : colors.darkColor1,
                  }}
                  align="left"
                >
                  <Typography style={{ fontSize: "12px" }}>
                    {row.keyID}
                  </Typography>
                </TableCell>

                <TableCell
                  style={{
                    color: state.darkModeEnabled
                      ? colors.lightColor1
                      : colors.darkColor1,
                  }}
                  align="left"
                >
                  {checkBindingExists(row) ? (
                    <Button
                      variant="outlined"
                      style={{
                        minWidth: "100px",
                        color: state.darkModeEnabled
                          ? colors.lightColor1
                          : colors.darkColor1,
                        borderColor: state.darkModeEnabled
                          ? colors.lightColor1
                          : colors.darkColor1,
                      }}
                      classes={{ disabled: classes.disabledButton }}
                      onClick={() => {
                        removeBinding(row);
                      }}
                    >
                      <Typography>Remove</Typography>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{
                        minWidth: "100px",
                        color: state.darkModeEnabled
                          ? colors.darkColor1
                          : colors.lightColor1,
                        backgroundColor: state.darkModeEnabled
                          ? colors.lightColor1
                          : colors.darkColor1,
                      }}
                      onClick={() => {
                        addBinding(row);
                      }}
                    >
                      <Typography>Add</Typography>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>

    </div>
  );
}
