import React from "react";
import Multiviewer from "./Multiviewer";
import Box from "@material-ui/core/Box";
import { Card } from "@material-ui/core";

export default function RouterPanelMV(props) {
  return (
    <div>
      <Card>
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          alignItems="center"
        >
          <Multiviewer feedID={props.sourceGroupForStream}></Multiviewer>
        </Box>
      </Card>
    </div>
  );
}
