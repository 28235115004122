import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { UserContext } from "../contexts/UserProvider";
import { colors, fonts } from "./Theme";
import { makeStyles, withStyles } from "@material-ui/core/styles";

export default function RegionSelector(props) {
  const [state, dispatch] = React.useContext(UserContext);

  const handleChange = (event) => {
    dispatch({ type: "selectRegion", value: event.target.value });
  };

  const useStyles = makeStyles({
    root: {
      fontFamily: `${fonts.medium} !important `,
      color: `${
        state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1
      } !important `,
      fontSize: `12px !important `,
      backgroundColor: ` ${
        state.darkModeEnabled ? colors.darkColor1 : colors.lightColor1
      } !important`,
    },
    selected: {
      color: `${
        state.darkModeEnabled ? colors.darkColor1 : colors.lightColor1
      } !important `,
      backgroundColor: ` ${
        state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1
      } !important`,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    props.updateStandardRegion();
  }, [state.selectedRegion]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="left"
        alignContent="left"
        sx={{ marginTop: "20px" }}
      >
        <Box sx={{ minWidth: 420, paddingBottom: "20px", paddingTop: 2 }}>
          <FormControl fullWidth>
            <InputLabel
              style={{
                fontFamily: fonts.medium,
                color: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
                borderBlockColor: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
              }}
              id="selectRegion"
            >
              Preferred Streaming Region
            </InputLabel>
            <Select
              variant="standard"
              labelId="selectRegion"
              id="selectRegion-id"
              value={state.selectedRegion}
              label="Preferred Streaming Region"
              onChange={handleChange}
              style={{
                fontFamily: fonts.medium,
                color: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
                marginLeft: "2px",
                paddingTop: "10px",
              }}
              sx={{
                ":before": {
                  borderBottomColor: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.mediumColor,
                },
                ":after": {
                  borderBottomColor: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.mediumColor,
                },
              }}
            >
              <MenuItem
                classes={{ selected: classes.selected, root: classes.root }}
                value={"EU"}
                selected
              >
                EU region
              </MenuItem>
              <MenuItem
                classes={{ selected: classes.selected, root: classes.root }}
                value={"NA"}
                selected
              >
                NA region
              </MenuItem>
              <MenuItem
                classes={{ selected: classes.selected, root: classes.root }}
                value={"AS"}
                selected
              >
                APAC region
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </div>
  );
}
