import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import Multiviewer from "./Multiviewer";
import Box from "@material-ui/core/Box";
import { Card, Button, Alert } from "react-bootstrap";
import MultiviewTabs from "./MultiviewerSelect";

export default function MultiviewerPanel() {
  useEffect(() => {}, []);

  return (
    <div>
      <MultiviewTabs></MultiviewTabs>
    </div>
  );
}
