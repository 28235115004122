import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RouterPanel from "./RouterPanel";
import SettingsPanel from "./SettingsPanel";
import MultiviewerPanel from "./MultiviewerPanel";
import { withStyles } from "@material-ui/core/styles";
import { fonts, colors } from "./Theme";
import Button from "@material-ui/core/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserContext } from "../contexts/UserProvider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CenteredTabs(props) {
  const [state, dispatch] = React.useContext(UserContext);
  const [value, setValue] = React.useState(0);

  const StyledTab = withStyles((theme) => ({}))((props) => (
    <Tab
      style={{
        fontFamily: fonts.medium,
        color: state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1,
        fontSize: "19px",
      }}
      {...props}
    />
  ));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: state.darkModeEnabled
          ? "#1e2019"
          : colors.lightColor1,
        opacity: "98%",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "center",
          paddingLeft: "5px",
        }}
      >
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: state.darkModeEnabled
                ? colors.lightColor1
                : colors.darkColor1,
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="selection tabs"
          variant="scrollable"
          allowScrollButtonsMobile

        >
          <StyledTab label="Router Panel" {...a11yProps(0)}>
          </StyledTab>
          <StyledTab label="Settings" {...a11yProps(1)} />
          {state.streamingData.enableMultiviewer ? (
            <StyledTab label="Multiviewer" {...a11yProps(2)} />
          ) : null}
        </Tabs>

        <Button
          style={{
            color: state.darkModeEnabled
              ? colors.lightColor1
              : colors.darkColor1,
            marginBottom: "5px",
            padding: "10px",
            alignSelf: "flex-end",
            marginLeft: "auto",
            minWidth: "70px",
            marginRight: "5px",
          }}
          onClick={props.handleLogout}
        >
          <LogoutIcon />
        </Button>
      </Box>
      <TabPanel value={value} index={0}>
        <RouterPanel
          take={props.createTakeRequest}
          changeGlobalLockStatus={props.changeGlobalLockStatus}
        ></RouterPanel>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SettingsPanel
          changeGlobalLockStatus={props.changeGlobalLockStatus}
          updateGlobalDarkMode={props.updateGlobalDarkMode}
          updateStandardRegion={props.updateStandardRegion}
        ></SettingsPanel>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MultiviewerPanel />
      </TabPanel>
    </Box>
  );
}
